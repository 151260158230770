import React from 'react';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridApi,
  GridCellParams,
  GridGroupNode,
  MuiEvent,
} from '@mui/x-data-grid-premium';

interface Props extends DataGridPremiumProps {
  apiRef: React.MutableRefObject<GridApi>;
}

const AppDataGrid = ({ apiRef, ...props }: Props) => {
  return (
    <DataGridPremium
      apiRef={apiRef}
      disableColumnSelector
      disableColumnReorder
      disableColumnFilter
      disableRowSelectionOnClick
      hideFooter
      ignoreDiacritics
      groupingColDef={{
        hideDescendantCount: true,
      }}
      onCellClick={(
        params: GridCellParams,
        event: MuiEvent<React.MouseEvent>
      ) => {
        if (!params.isEditable) {
          event.defaultMuiPrevented = true;
        }
        if (
          params.rowNode.type === 'group' &&
          params.colDef.type === 'expandAction'
        ) {
          apiRef.current.setRowChildrenExpansion(
            params.rowNode.id,
            !apiRef.current.getRowNode<GridGroupNode>(params.rowNode.id)!
              .childrenExpanded
          );
        }
      }}
      onProcessRowUpdateError={() => {}}
      {...props}
    ></DataGridPremium>
  );
};

export default AppDataGrid;
