import { createTheme, LinkProps } from '@mui/material';
import { frFR } from '@mui/material/locale';
import { frFR as frFrGrid } from '@mui/x-data-grid';
import { forwardRef } from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return <Link ref={ref} to={href} {...other} />;
});

export const theme = createTheme(
  {
    typography: {
      fontFamily: [
        '"Montserrat"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif',
      ].join(','),
      h2: {
        fontWeight: 600,
        fontSize: '25px',
      },
      h3: {
        fontWeight: 500,
        fontSize: '16px',
      },
    },
    palette: {
      primary: {
        main: '#f15c16',
      },
      secondary: {
        main: 'rgba(68,68,68,0.91)',
      },
      text: {
        secondary: '#949494',
      },
    },
    components: {
      MuiCardActionArea: {
        styleOverrides: {
          focusHighlight: {
            background: 'none',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            alignItems: 'start',
          },
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  },
  frFR,
  frFrGrid
);
