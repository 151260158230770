import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { emailValidator, useForm } from 'src/hooks/useForm';
import { Department, DepartmentList } from 'shared/types/Department';
import { DraftUser } from 'src/models/User';
import { DepartmentLabels } from 'src/models/Phase';
import { WeeklyHours, WeeklyHoursList } from 'shared/types/WeeklyHours';

export interface Props {
  onSubmit: (user: DraftUser) => void;
  open: boolean;
  onClose: () => void;
}

function AddUserDialog({ onSubmit, onClose, open }: Props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState<Department>();
  const [manager, setManager] = useState<boolean>(false);
  const [hourlyRate, setHourlyRate] = useState<number>(0);
  const [weeklyHours, setWeeklyHours] = useState<WeeklyHours>();

  const schema = yup.object().shape({
    firstName: yup.string().required('Veuillez renseigner le prénom.'),
    lastName: yup.string().required('Veuillez renseigner le nom.'),
    email: emailValidator,
    department: yup.string().required('Veuillez renseigner le département.'),
    manager: yup.boolean(),
    hourlyRate: yup
      .number()
      .required('Veuillez renseigner le taux horaire.')
      .min(0, 'Le taux horaire doit être positif.'),
    weeklyHours: yup
      .number()
      .required('Veuillez renseigner les heures hebdomadaires.'),
  });

  const { message, validate, messageType } = useForm(schema, {
    firstName,
    lastName,
    email,
    department,
    manager,
    hourlyRate,
    weeklyHours,
  });

  const submitUser = async () => {
    await validate(() =>
      onSubmit({
        firstName,
        lastName,
        email,
        department: department!,
        manager,
        role: 'Employee',
        hourlyRate,
        weeklyHours: weeklyHours!,
      })
    );
  };

  return (
    <Box component="form" onSubmit={submitUser}>
      <Dialog open={open} scroll="paper" disableScrollLock fullWidth>
        <DialogTitle>Nouveau collaborateur</DialogTitle>

        <DialogContent>
          <TextField
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            autoFocus
            label="Prénom"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('firstName') === 'error'}
          />
          <FormHelperText error>{message('firstName')}</FormHelperText>
          <TextField
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            label="Nom"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('lastName') === 'error'}
          />
          <FormHelperText error>{message('lastName')}</FormHelperText>
          <TextField
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('email') === 'error'}
          />
          <FormHelperText error>{message('email')}</FormHelperText>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="select-department-label">Département</InputLabel>
            <Select
              labelId="select-department-label"
              value={department ?? ''}
              onChange={(event) =>
                setDepartment(event.target.value as Department)
              }
              MenuProps={{
                PaperProps: { sx: { maxHeight: 100 } },
              }}
              autoWidth
            >
              {DepartmentList.map((department) => (
                <MenuItem value={department} key={department}>
                  {DepartmentLabels[department]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{message('department')}</FormHelperText>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={manager}
                onChange={(event) => setManager(event.target.checked)}
                name="manager"
                color="primary"
              />
            }
            label="Chef de projet"
          />
          <TextField
            value={hourlyRate}
            onChange={(event) => setHourlyRate(Number(event.target.value))}
            label="Taux horaire"
            type="number"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('hourlyRate') === 'error'}
          />
          <FormHelperText error>{message('hourlyRate')}</FormHelperText>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="select-weekly-hours-label">
              Heures hebdomadaires
            </InputLabel>
            <Select
              labelId="select-weekly-hours-label"
              value={weeklyHours ?? ''}
              onChange={(event) =>
                setWeeklyHours(event.target.value as WeeklyHours)
              }
              MenuProps={{
                PaperProps: { sx: { maxHeight: 100 } },
              }}
              autoWidth
            >
              {WeeklyHoursList.map((weeklyHours) => (
                <MenuItem value={weeklyHours} key={weeklyHours}>
                  {weeklyHours}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{message('weeklyHours')}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={submitUser}>
            Créer le colaborateur
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddUserDialog;
