import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'src/hooks/useForm';
import { useFindProjectsQuery } from 'src/services/project.service';
import { useFindPhasesQuery } from 'src/services/phase.service';

export interface Props {
  onSubmit: (phaseId: string) => void;
  open: boolean;
  onClose: () => void;
  excludedPhaseIds?: string[];
}

function PhaseSelectDialog({
  onSubmit,
  onClose,
  open,
  excludedPhaseIds,
}: Props) {
  const [projectId, setProjectId] = useState<string>('');
  const [phaseId, setPhaseId] = useState<string>('');

  const { data: projects } = useFindProjectsQuery();
  const { data: phases } = useFindPhasesQuery(
    { projectId },
    { skip: !projectId }
  );

  const schema = yup.object().shape({
    projectId: yup
      .string()
      .required()
      .required('Veuillez renseigner le projet.'),
    phaseId: yup.string().required().required('Veuillez renseigner la phase.'),
  });

  const { message, validate, messageType } = useForm(schema, {
    projectId,
    phaseId,
  });

  const submit = async () => {
    await validate(() => onSubmit(phaseId));
  };

  if (!projects) {
    return <></>;
  }

  return (
    <Box component="form" onSubmit={submit}>
      <Dialog open={open} scroll="paper" disableScrollLock fullWidth>
        <DialogTitle>Ajouter une ligne d'heures</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('projectId') === 'error'}
          >
            <InputLabel id="project-label">Projet</InputLabel>
            <Select
              labelId="project-label"
              id="project-select"
              value={projectId}
              label="Projet"
              onChange={(event) => setProjectId(event.target.value)}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 200 } },
              }}
            >
              {projects.map((project) => (
                <MenuItem value={project.id} key={project.id}>
                  {project.reference} - {project.title}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{message('projectId')}</FormHelperText>
          </FormControl>
          {projectId && (
            <FormControl
              fullWidth
              variant="standard"
              margin="normal"
              error={messageType('phaseId') === 'error'}
            >
              <InputLabel id="phase-label">Phase</InputLabel>
              <Select
                labelId="phase-label"
                id="phase-select"
                value={phaseId}
                label="Phase"
                onChange={(event) => setPhaseId(event.target.value)}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 200 } },
                }}
              >
                {phases
                  ?.filter((phase) => !excludedPhaseIds?.includes(phase.id))
                  .map((phase) => (
                    <MenuItem value={phase.id} key={phase.id}>
                      {phase.kind}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error>{message('phaseId')}</FormHelperText>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={submit}>
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default PhaseSelectDialog;
