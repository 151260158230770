import React, { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { Edit } from '@mui/icons-material';
import {
  useGetProjectQuery,
  useUpdateProjectMutation,
} from 'src/services/project.service';
import ProjectDialog from 'src/components/ProjectList/ProjectDialog';
import { Project } from 'src/models/Project';

export interface Props {
  projectId: string;
}

function ProjectEditCell({ projectId }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: project } = useGetProjectQuery(projectId!, {
    skip: !dialogOpen,
  });
  const [updateProject] = useUpdateProjectMutation();

  const submitProject = async (project: Project) => {
    await updateProject(project);
    setDialogOpen(false);
  };

  return (
    <>
      <GridActionsCellItem
        icon={<Edit />}
        onClick={() => {
          setDialogOpen(true);
        }}
      />
      {project && (
        <ProjectDialog
          project={project}
          onClose={() => setDialogOpen(false)}
          open={dialogOpen}
          onSubmit={(project) => submitProject(project as Project)}
        />
      )}
    </>
  );
}

export default ProjectEditCell;
