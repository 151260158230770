import { GridColDef } from '@mui/x-data-grid-premium';
import { Department } from 'shared/types/Department';
import { getRangeFee, PhaseWithRange } from 'src/models/Phase';

export const projectReferenceColDef = (groupable: boolean): GridColDef => ({
  field: 'projectReference',
  headerName: 'Réf',
  width: groupable ? 80 : 100,
  align: 'center',
  headerAlign: 'center',
  groupable: false,
  aggregable: false,
  sortable: false,
  pinnable: false,
  disableColumnMenu: true,
  ...(groupable && {
    cellClassName: 'expand-action',
    type: 'expandAction',
  }),
});

export const projectTitleColDef = (groupable: boolean): GridColDef => ({
  field: 'projectTitle',
  headerName: 'Titre',
  width: 300,
  groupable: false,
  aggregable: false,
  sortable: false,
  pinnable: false,
  disableColumnMenu: true,
  ...(groupable && {
    cellClassName: 'expand-action',
    type: 'expandAction',
  }),
});

export const PhaseKindColDef: GridColDef = {
  field: 'kind',
  headerName: 'Phase',
  width: 120,
  align: 'center',
  headerAlign: 'center',
};

export const currencyFormatter = (
  params: { value: number },
  withDefaultValue = true
): string =>
  params.value || withDefaultValue
    ? Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(params.value ?? 0)
    : '';

export const currencyColDef = (
  field: string,
  headerName: string,
  withDefaultValue = true
): GridColDef => ({
  field,
  headerName,
  type: 'number',
  width: 150,
  valueFormatter: (value) => currencyFormatter(value, withDefaultValue),
  groupable: false,
  availableAggregationFunctions: ['sum', 'avg', 'min', 'max'],
  editable: true,
  filterable: false,
  sortable: false,
  disableColumnMenu: true,
});

export const feeColDef = (
  field: string,
  headerName: string,
  department?: Department
): GridColDef => ({
  field,
  headerName,
  type: 'number',
  width: 150,
  valueFormatter: currencyFormatter,
  groupable: false,
  availableAggregationFunctions: [
    'feeSum',
    'feeSumThermal',
    'feeSumElectricity',
    'feeSumStructural',
    'feeSumEconomy',
  ],
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  valueGetter: (params) =>
    getRangeFee(params.row as PhaseWithRange, department),
});

export const rateColDef = (field: string, headerName: string): GridColDef => ({
  field,
  headerName,
  width: 120,
  type: 'number',
  editable: true,
  filterable: false,
  disableColumnMenu: true,
  sortable: false,
  valueFormatter: (params) => `${params.value ?? 0}%`,
});

export const hourColDef = (field: string, headerName: string): GridColDef => ({
  field,
  headerName,
  type: 'number',
  filterable: false,
  width: 100,
  sortable: false,
  resizable: false,
  disableColumnMenu: true,
  valueFormatter: (params) => `${params.value ?? 0} h`,
});
