import React, { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { Person, PersonOff } from '@mui/icons-material';
import AppSmallBadge from 'src/components/AppSmallBadge/AppSmallBadge';
import {
  Button,
  Checkbox,
  DialogActions,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import { useFindUsersQuery } from 'src/services/user.service';
import { Department } from 'shared/types/Department';
import { useUpdatePhaseUsersMutation } from 'src/services/phase.service';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';

export interface Props {
  phaseId: string;
  userIds: string[];
  department: Department;
}

function PhasesUsersEditCell({ phaseId, userIds, department }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [checked, setChecked] = React.useState(userIds);
  const [moreUsers, setMoreUsers] = React.useState(false);

  const { data: allUsers } = useFindUsersQuery();
  const [updatePhaseUsers] = useUpdatePhaseUsersMutation({
    fixedCacheKey: 'shared-update-phase-users',
  });

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const submitUsers = async () => {
    await updatePhaseUsers({ phaseId, department, userIds: checked });
    setDialogOpen(false);
  };

  const onClose = () => {
    setChecked(userIds);
    setDialogOpen(false);
  };

  return (
    <>
      <GridActionsCellItem
        icon={
          userIds.length ? (
            <Tooltip
              placement="top"
              title={userIds.map((userId) => {
                const user = allUsers?.find((user) => user.id === userId);
                return (
                  <div key={userId}>
                    {user?.firstName} {user?.lastName}
                  </div>
                );
              })}
            >
              <AppSmallBadge
                badgeContent={userIds.length}
                color="default"
                overlap="circular"
              >
                <Person color="action" />
              </AppSmallBadge>
            </Tooltip>
          ) : (
            <AppSmallBadge color="default" overlap="circular">
              <PersonOff color="action" />
            </AppSmallBadge>
          )
        }
        onClick={() => {
          setDialogOpen(true);
        }}
      />
      <Dialog open={dialogOpen} onClose={onClose}>
        <DialogContent>
          <List>
            {(allUsers ?? [])
              .filter(
                (user) =>
                  moreUsers ||
                  user.department === department ||
                  checked.includes(user.id)
              )
              .map((user) => {
                const labelId = `checkbox-user-${user.id}`;

                return (
                  <ListItem disablePadding key={labelId}>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(user.id)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(user.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${user.firstName} ${user.lastName}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
          {!moreUsers && (
            <Link
              onClick={() => {
                setMoreUsers(true);
              }}
            >
              Voir tous les utilisateurs
            </Link>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={submitUsers}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PhasesUsersEditCell;
