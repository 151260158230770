import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import PhaseSelectDialog from 'src/components/PhaseSelectDialog/PhaseSelectDialog';
import { useSaveHourByDatesMutation } from 'src/services/hours.service';
import { useAuthentication } from 'src/hooks/useAuthentication';

interface Props {
  startDate: Date;
  excludedPhaseIds?: string[];
}

const AddPhaseHoursRowButton = ({ startDate, excludedPhaseIds }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [saveHourByDates] = useSaveHourByDatesMutation();
  const { userId } = useAuthentication();

  if (!userId) {
    return <></>;
  }

  const submitPhaseHoursRow = async (phaseId: string) => {
    await saveHourByDates({
      phaseId,
      userId,
      hoursByDate: [{ date: startDate, hours: 0 }],
    });
    setDialogOpen(false);
  };

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setDialogOpen(true)}>
        Ajouter une ligne d'heures
      </Button>
      <PhaseSelectDialog
        onSubmit={submitPhaseHoursRow}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        excludedPhaseIds={excludedPhaseIds}
      />
    </>
  );
};

export default AddPhaseHoursRowButton;
