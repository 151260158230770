import React from 'react';
import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Box, CircularProgress, ThemeProvider } from '@mui/material';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { theme } from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { fr } from 'date-fns/locale';
import SigninView from 'src/views/SigninView/SigninView';
import { useAuthentication } from 'src/hooks/useAuthentication';
import FetchInterceptor from 'src/components/FetchInterceptor/FetchInterceptor';
import HomeView from 'src/views/HomeView/HomeView';
import AccountView from 'src/views/AccountView/AccountView';
import HourRecordingView from 'src/views/HourRecordingView/HourRecordingView';
import SetPasswordView from 'src/views/SetPasswordView/SetPasswordView';
import { useAppSelector } from 'src/hooks/useStore';

function AppWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <App />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  );
}

function App() {
  const { isAuthenticated, availableRoutes } = useAuthentication();
  const isSomeQueryPending = useAppSelector((state) =>
    Object.values(state.api.queries).some(
      (query) => query?.status === 'pending'
    )
  );

  FetchInterceptor();

  return (
    <React.Suspense fallback={<></>}>
      <Header />
      {isSomeQueryPending && (
        <Box
          sx={{
            textAlign: 'center',
            position: 'absolute',
            top: { xs: 38, md: 60 },
            width: '100%',
            zIndex: 1200,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 56, md: 64 },
          width: '100%',
          paddingBottom: '3rem',
        }}
      >
        <Routes>
          {isAuthenticated && [
            ...availableRoutes.map((route) => (
              <Route
                path={route.path}
                element={<route.component />}
                key={route.key}
              />
            )),
            <Route
              path="/mon-compte"
              element={<AccountView />}
              key="account_route"
            />,
            <Route
              path="/mes-heures"
              element={<HourRecordingView />}
              key="hours_route"
            />,
            <Route path="/" element={<HomeView />} key="root_route" />,
          ]}
          {!isAuthenticated && [
            <Route
              path="/connexion"
              element={<SigninView />}
              key="signin_route"
            />,
            <Route
              path="/mot-de-passe"
              element={<SetPasswordView />}
              key="signin_route"
            />,
            <Route path="/" element={<SigninView />} key="root_route" />,
          ]}
          <Route path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </Box>
      <Footer />
    </React.Suspense>
  );
}

export default AppWrapper;
