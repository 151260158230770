import { format } from 'date-fns';
import { PhaseKind } from 'shared/types/PhaseKind';
import { Phase } from 'src/models/Phase';
import { Hour } from 'src/models/Hour';

export interface DateHours {
  date: Date;
  hours: number;
}

export interface PhaseHoursByDate {
  phaseId: string;
  hoursByDate: DateHours[];
}

export interface PhaseHoursByDateRow extends PhaseHoursByDate {
  id: string;
  projectReference: string;
  projectTitle: string;
  kind: PhaseKind;
}

export const getPhaseHoursByDateList = (
  currentUserPhases: Phase[],
  hours: Hour[]
): PhaseHoursByDate[] =>
  hours.reduce(
    (acc, hour) => {
      const phase = acc.find((phase) => phase.phaseId === hour.phaseId);
      if (phase) {
        phase.hoursByDate.push({ date: hour.date, hours: hour.hours });
      } else {
        acc.push({
          phaseId: hour.phaseId,
          hoursByDate: [{ date: hour.date, hours: hour.hours }],
        });
      }
      return acc;
    },
    currentUserPhases.map((phase) => ({
      phaseId: phase.id,
      hoursByDate: [] as DateHours[],
    }))
  );

export const getHoursByDate = (
  phaseHoursByDateRow: PhaseHoursByDateRow,
  date: Date
) =>
  phaseHoursByDateRow.hoursByDate?.find(
    (hour) => format(hour.date, 'ddMMyyyy') === format(date, 'ddMMyyyy')
  )?.hours;

export const setHoursByDate = (
  phaseHoursByDateRow: PhaseHoursByDateRow,
  date: Date,
  hours: number
): PhaseHoursByDateRow => {
  const hourIndex = phaseHoursByDateRow.hoursByDate.findIndex(
    (hour) => format(hour.date, 'ddMMyyyy') === format(date, 'ddMMyyyy')
  );
  if (hourIndex !== -1) {
    return {
      ...phaseHoursByDateRow,
      hoursByDate: [
        ...phaseHoursByDateRow.hoursByDate.slice(0, hourIndex),
        { date, hours },
        ...phaseHoursByDateRow.hoursByDate.slice(hourIndex + 1),
      ],
    };
  } else {
    return {
      ...phaseHoursByDateRow,
      hoursByDate: [...phaseHoursByDateRow.hoursByDate, { date, hours }],
    };
  }
};
