import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useCreateProjectMutation } from 'src/services/project.service';
import AddProjectDialog from 'src/components/ProjectList/AddProjectDialog';
import { DraftProject } from 'src/models/Project';

const AddProjectButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [createProject] = useCreateProjectMutation();

  const submitProject = async (project: DraftProject) => {
    await createProject(project);
    setDialogOpen(false);
  };

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setDialogOpen(true)}>
        Ajouter une affaire
      </Button>
      <AddProjectDialog
        onSubmit={submitProject}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default AddProjectButton;
