import React from 'react';
import { Box } from '@mui/material';
import {
  GRID_AGGREGATION_FUNCTIONS,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridAggregationFunction,
  GridColDef,
  GridRowsProp,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { useFindPhasesQuery } from 'src/services/phase.service';
import { getRangeFee, PhaseWithRange } from 'src/models/Phase';
import { Department } from 'shared/types/Department';
import {
  feeColDef,
  PhaseKindColDef,
  projectReferenceColDef,
  projectTitleColDef,
} from 'src/utils/columnUtils';
import fp from 'lodash/fp';
import { isDefined } from 'shared/utils/utils';
import ExportToolbar from 'src/components/ExportToolbar/ExportToolbar';
import { useDataGrid } from 'src/hooks/useDataGrid';
import AppDataGrid from 'src/components/AppDataGrid/AppDataGrid';
import { isValid } from 'date-fns';

interface Props {
  startDate: Date;
  endDate: Date;
}
const PlanningProjectList = ({ startDate, endDate }: Props) => {
  const { apiRef, isGroupExpandedByDefault, initialStateFilter } =
    useDataGrid('planningProject');

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...initialStateFilter,
      rowGrouping: {
        model: ['projectId'],
      },
      aggregation: {
        model: {
          fee: 'feeSum',
          thermalFee: 'feeSumThermal',
          electricityFee: 'feeSumElectricity',
          structuralFee: 'feeSumStructural',
          economyFee: 'feeSumEconomy',
        },
      },
      pinnedColumns: {
        left: [
          'projectId',
          'projectReference',
          'projectTitle',
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
          'kind',
        ],
      },
    },
  });

  const { data } = useFindPhasesQuery(
    {
      startDate: isValid(startDate) ? startDate.toISOString() : undefined,
      endDate: isValid(endDate) ? endDate.toISOString() : undefined,
    },
    {
      skip: !isValid(startDate) || !isValid(endDate),
      refetchOnMountOrArgChange: true,
    }
  );
  const phases = data ?? [];

  const getProject = (id: string) => {
    return phases.find((phase) => phase.project.id === id)?.project;
  };

  const rows: GridRowsProp = phases.map((phase) => ({
    ...phase,
    projectReference: phase.project.reference,
    projectTitle: phase.project.title,
    projectId: phase.project.id,
    range: { startDate, endDate },
  }));

  const columns: GridColDef[] = [
    {
      ...projectReferenceColDef(true),
      renderCell: (params) =>
        params.rowNode.type === 'group'
          ? getProject(params.rowNode.groupingKey as string)?.reference
          : '',
    },
    {
      ...projectTitleColDef(true),
      renderCell: (params) =>
        params.rowNode.type === 'group'
          ? getProject(params.rowNode.groupingKey as string)?.title
          : '',
    },
    {
      field: 'projectId',
      headerName: '',
      type: 'string',
      filterable: false,
      width: 1,
      sortable: false,
      resizable: false,
      aggregable: false,
      disableColumnMenu: true,
      renderCell: () => <></>,
    },
    {
      ...PhaseKindColDef,
      width: 65,
      groupable: false,
      aggregable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    feeColDef('fee', 'Global'),
    feeColDef('thermalFee', 'Thermique', 'THERMAL'),
    feeColDef('electricityFee', 'Electricité', 'ELECTRICITY'),
    feeColDef('structuralFee', 'Structure', 'STRUCTURAL'),
    feeColDef('economyFee', 'Economie', 'ECONOMY'),
  ];

  const feeSum = (
    department?: Department
  ): GridAggregationFunction<PhaseWithRange, string> => ({
    label: 'honoraires',
    getCellValue: ({ row }) => row as PhaseWithRange,
    apply: ({ values }) =>
      fp
        .sum(
          values
            .filter(isDefined)
            .map((phase) => getRangeFee(phase, department))
        )
        .toString(),
    columnTypes: ['number'],
  });

  const toolbar = () => (
    <>
      <ExportToolbar showQuickFilter={true} />
    </>
  );

  return (
    <>
      <Box height="calc(100vh - 250px)">
        <AppDataGrid
          apiRef={apiRef}
          initialState={initialState}
          rows={rows}
          columns={columns}
          isCellEditable={() => false}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
          slots={{ toolbar }}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            feeSum: feeSum(),
            feeSumThermal: feeSum('THERMAL'),
            feeSumElectricity: feeSum('ELECTRICITY'),
            feeSumStructural: feeSum('STRUCTURAL'),
            feeSumEconomy: feeSum('ECONOMY'),
          }}
        />
      </Box>
    </>
  );
};

export default PlanningProjectList;
