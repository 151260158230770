import React from 'react';
import { Box, Button, Container } from '@mui/material';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { addMonths, format } from 'date-fns';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { fr } from 'date-fns/locale';
import PlanningHourList from 'src/views/PlanningHourView/PlanningHourList';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';

const PlanningHourView = () => {
  useDocumentTitle('Gestion des heures');
  const { isAdmin } = useAuthentication();

  const [current, setCurrent] = React.useState(new Date());

  if (!isAdmin) {
    return <></>;
  }

  return (
    <Container maxWidth="xl" sx={{ marginTop: '3rem' }}>
      <Box sx={{ marginBottom: '1rem', textAlign: 'center' }}>
        <Button onClick={() => setCurrent(addMonths(current, -1))}>
          <ArrowBack />
        </Button>
        {format(current, 'MMMM yyyy', {
          locale: fr,
        })}
        <Button onClick={() => setCurrent(addMonths(current, 1))}>
          <ArrowForward />
        </Button>
      </Box>
      <PlanningHourList current={current} />
    </Container>
  );
};

export default PlanningHourView;
