import React from 'react';
import { Box, Button, Container } from '@mui/material';
import { addWeeks, endOfWeek, format, startOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { weekOptions } from 'src/utils/dateUtils';
import HourRecordingList from 'src/views/HourRecordingView/HourRecordingList';
import { H1 } from 'src/components/Styled/Styled';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';

const HourRecordingView = () => {
  useDocumentTitle('Saisie des heures hebdomadaires');
  const [current, setCurrent] = React.useState(new Date());

  return (
    <Container maxWidth="xl">
      <H1>Saisie des heures hebdomadaires</H1>
      <Box sx={{ marginBottom: '1rem', textAlign: 'center' }}>
        <Button onClick={() => setCurrent(addWeeks(current, -1))}>
          <ArrowBack />
        </Button>
        Du 
        {format(startOfWeek(current, weekOptions), 'eeee dd MMMM yyyy', {
          locale: fr,
        })}
         au 
        {format(endOfWeek(current, weekOptions), 'eeee dd MMMM yyyy', {
          locale: fr,
        })}
        <Button onClick={() => setCurrent(addWeeks(current, 1))}>
          <ArrowForward />
        </Button>
      </Box>
      <HourRecordingList current={current} />
    </Container>
  );
};

export default HourRecordingView;
