import React from 'react';
import { useGetProjectQuery } from 'src/services/project.service';

interface Props {
  projectId: string;
}

const ProjectReference = ({ projectId }: Props) => {
  const { data: project } = useGetProjectQuery(projectId);

  return <>{project?.reference}</>;
};

export default ProjectReference;
