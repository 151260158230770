import React from 'react';
import { Box, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DepartmentLabels } from 'src/models/Phase';
import {
  useFindUsersQuery,
  useUpdateUserMutation,
} from 'src/services/user.service';
import { Department, DepartmentList } from 'shared/types/Department';
import {
  GridRowParams,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridValidRowModel,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';
import { Check, Send } from '@mui/icons-material';
import AppSnackbar from 'src/components/AppSnackbar/AppSnackbar';
import { User } from 'src/models/User';
import AddUserButton from 'src/components/UserList/AddUserButton';
import { currencyColDef } from 'src/utils/columnUtils';
import { useSendPasswordLinkMutation } from 'src/services/account.service';
import { useDataGrid } from 'src/hooks/useDataGrid';
import { WeeklyHoursList } from 'shared/types/WeeklyHours';
import _ from 'lodash';

const UserList = () => {
  const { apiRef, initialStateFilter } = useDataGrid('user');

  const initialState = {
    ...initialStateFilter,
    sorting: {
      sortModel: [{ field: 'department', sort: 'asc' as GridSortDirection }],
    },
  };

  const [updateUser, { isSuccess: isUpdateSuccess }] = useUpdateUserMutation();
  const [sendPasswordLink, { isSuccess: isSendEmailSuccess }] =
    useSendPasswordLinkMutation();

  const { data } = useFindUsersQuery();
  const users = data ?? [];

  const processRowUpdate = async (
    updatedRow: GridValidRowModel,
    originalRow: GridValidRowModel
  ) => {
    if (!_.isEqual(updatedRow, originalRow)) {
      await updateUser(updatedRow as User);
    }
    return updatedRow;
  };

  const sendLink = async (email: string) => {
    await sendPasswordLink(email);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Prénom',
      width: 200,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Nom',
      width: 200,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: true,
    },
    {
      field: 'department',
      headerName: 'Département',
      width: 200,
      editable: true,
      type: 'singleSelect',
      valueOptions: DepartmentList.map(
        (department) => DepartmentLabels[department]
      ),
      valueGetter: (params) =>
        DepartmentLabels[params.row.department as Department],
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        department: DepartmentList.find(
          (department) => DepartmentLabels[department] === params.value
        ),
      }),
    },
    {
      field: 'manager',
      headerName: 'Chef de projet',
      width: 200,
      type: 'boolean',
      editable: true,
      renderCell: (params) => {
        return params.value ? <Check color="primary" /> : <></>;
      },
    },
    currencyColDef('hourlyRate', 'Taux horaire'),
    {
      field: 'weeklyHours',
      headerName: 'Heures hebdo',
      width: 120,
      editable: true,
      type: 'singleSelect',
      valueOptions: WeeklyHoursList,
      align: 'center',
    },
    {
      field: 'accountLink',
      headerName: 'Mot de passe',
      type: 'actions',
      width: 200,
      getActions: ({ row }: GridRowParams<User>) => [
        <IconButton
          title="Envoyer un mot de passe par mail"
          onClick={() => {
            sendLink(row.email);
          }}
        >
          <Send />
        </IconButton>,
      ],
    },
  ];

  const toolbar = () => (
    <GridToolbarContainer>
      <AddUserButton />
      <Box sx={{ flexGrow: 1 }}></Box>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );

  return (
    <>
      <AppSnackbar
        isOpen={isUpdateSuccess}
        severity="success"
        message="Modification enregistrée"
      />
      <AppSnackbar
        isOpen={isSendEmailSuccess}
        severity="success"
        message="Un mail a été envoyé à l'utilisateur pour qu'il choisisse son mot de passe"
      />
      <Box height="calc(100vh - 250px)">
        <DataGrid
          apiRef={apiRef}
          initialState={initialState}
          rows={users}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          hideFooter
          slots={{ toolbar }}
        />
      </Box>
    </>
  );
};

export default UserList;
