import React from 'react';
import { Container } from '@mui/material';
import ProjectList from 'src/components/ProjectList/ProjectList';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';

const ProjectsView = () => {
  useDocumentTitle('Affaires');
  const { isAdmin } = useAuthentication();

  if (!isAdmin) {
    return <></>;
  }

  return (
    <Container maxWidth="xl" sx={{ marginTop: '3rem' }}>
      <ProjectList />
    </Container>
  );
};

export default ProjectsView;
