import React from 'react';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { Box } from '@mui/material';

interface Props {
  additionalButtons?: React.ReactNode[];
  showQuickFilter?: boolean;
}

const ExportToolbar = ({ additionalButtons, showQuickFilter }: Props) => {
  return (
    <GridToolbarContainer>
      {additionalButtons}
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      {showQuickFilter && (
        <>
          <Box sx={{ flexGrow: 1 }}></Box>
          <GridToolbarQuickFilter />
        </>
      )}
    </GridToolbarContainer>
  );
};

export default ExportToolbar;
