import React from 'react';
import { Container } from '@mui/material';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { useSignInMutation } from 'src/services/account.service';
import { useNavigate } from 'react-router-dom';
import AccountSigninForm from 'src/components/Account/AccountSigninForm/AccountSigninForm';

const SigninView = () => {
  const navigate = useNavigate();
  useDocumentTitle('Connectez-vous');

  const [_, { isLoading: isSignInLoading }] = useSignInMutation(); // eslint-disable-line @typescript-eslint/no-unused-vars

  return (
    <Container maxWidth="sm" sx={{ marginTop: '5rem' }}>
      {!isSignInLoading && (
        <AccountSigninForm onConnect={() => navigate('/')} />
      )}
    </Container>
  );
};

export default SigninView;
