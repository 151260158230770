import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import AppSnackbar from 'src/components/AppSnackbar/AppSnackbar';
import {
  GRID_AGGREGATION_FUNCTIONS,
  GridEditInputCell,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';
import { addDays, endOfWeek, format, startOfWeek } from 'date-fns';
import { weekOptions } from 'src/utils/dateUtils';
import {
  currencyColDef,
  PhaseKindColDef,
  projectReferenceColDef,
  projectTitleColDef,
} from 'src/utils/columnUtils';
import {
  useFindHoursQuery,
  useLazyFindHoursQuery,
  useSaveHourByDatesMutation,
} from 'src/services/hours.service';
import { fr } from 'date-fns/locale';
import {
  useFindPhasesQuery,
  useLazyGetPhaseQuery,
} from 'src/services/phase.service';
import { isNotNull } from 'shared/utils/utils';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { v4 as uuidv4 } from 'uuid';
import AddPhaseHoursRowButton from 'src/views/HourRecordingView/AddPhaseHoursRowButton';
import {
  getHoursByDate,
  getPhaseHoursByDateList,
  PhaseHoursByDateRow,
  setHoursByDate,
} from 'src/models/PhaseHoursByDate';
import { useDataGrid } from 'src/hooks/useDataGrid';
import { getRemainedFee } from 'src/models/Phase';
import AppDataGrid from 'src/components/AppDataGrid/AppDataGrid';

const DateHeader = ({ date }: { date: Date }) => {
  return (
    <Box sx={{ lineHeight: 'initial', textAlign: 'center', fontWeight: '500' }}>
      {format(date, 'eeee', { locale: fr })}
      <br />
      {format(date, 'dd MMMM', { locale: fr })}
    </Box>
  );
};

interface ToolbarProps {
  current: Date;
  excludedPhaseIds: string[];
}

const Toolbar = ({ current, excludedPhaseIds }: ToolbarProps) => {
  return (
    <GridToolbarContainer sx={{ paddingBottom: 1 }}>
      <AddPhaseHoursRowButton
        startDate={startOfWeek(current, weekOptions)}
        excludedPhaseIds={excludedPhaseIds}
      />
      <Box sx={{ flexGrow: 1 }}></Box>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

interface Props {
  current: Date;
}

const HourRecordingList = ({ current }: Props) => {
  const { apiRef, initialStateFilter } = useDataGrid('hourRecording');

  const initialState = {
    ...initialStateFilter,
    aggregation: {
      model: {
        date1: 'sum',
        date2: 'sum',
        date3: 'sum',
        date4: 'sum',
        date5: 'sum',
      },
    },
    sorting: {
      sortModel: [
        { field: 'projectReference', sort: 'asc' as GridSortDirection },
      ],
    },
  };

  const { userId, userInfos } = useAuthentication();

  const { data: hoursData } = useFindHoursQuery({
    startDate: startOfWeek(current, weekOptions).toISOString(),
    endDate: endOfWeek(current, weekOptions).toISOString(),
    userId,
  });

  const { data: currentUserPhasesData } = useFindPhasesQuery({
    startDate: startOfWeek(current, weekOptions).toISOString(),
    endDate: endOfWeek(current, weekOptions).toISOString(),
    userId,
  });

  const [getPhaseQuery] = useLazyGetPhaseQuery();
  const [findHoursQuery] = useLazyFindHoursQuery();

  const [rows, setRows] = React.useState<PhaseHoursByDateRow[]>([]);

  useEffect(() => {
    (() =>
      Promise.all(
        getPhaseHoursByDateList(
          currentUserPhasesData ?? [],
          hoursData ?? []
        ).map(async (phaseHour) => {
          const { data: phase } = await getPhaseQuery(phaseHour.phaseId, true);
          const { data: phaseHours } = await findHoursQuery({
            phaseId: phaseHour.phaseId,
          });
          if (!phase) return null;
          return {
            id: uuidv4(),
            phaseId: phaseHour.phaseId,
            hoursByDate: phaseHour.hoursByDate,
            projectReference: phase.project.reference,
            projectTitle: phase.project.title,
            kind: phase.kind,
            remained: getRemainedFee(
              phase,
              phaseHours ?? [],
              userInfos?.department
            ),
          };
        })
      ).then((rows) => setRows(rows.filter(isNotNull))))();
  }, [hoursData, currentUserPhasesData]); // eslint-disable-line react-hooks/exhaustive-deps

  const [saveHourByDates, { isSuccess: isUpdateSuccess, error }] =
    useSaveHourByDatesMutation();

  if (!userId) return <></>;
  const processRowUpdate = async (updatedRow: GridValidRowModel) => {
    await saveHourByDates({
      phaseId: updatedRow.phaseId,
      userId,
      hoursByDate: updatedRow.hoursByDate,
    });
  };

  const dayColDef = (day: number): GridColDef => {
    const date = addDays(startOfWeek(current, weekOptions), day - 1);
    return {
      field: `date_${date.toISOString()}`,
      renderHeader: () => <DateHeader date={date} />,
      width: 130,
      editable: true,
      disableColumnMenu: true,
      aggregable: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      valueGetter: (params: GridValueGetterParams) =>
        getHoursByDate(params.row, date) ?? 0,
      valueSetter: (params: GridValueSetterParams) => {
        const phaseHours = params.row;
        return setHoursByDate(phaseHours, date, params.value as number);
      },
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 0,
            max: userInfos?.weeklyHours === 39 ? 8 : 7,
          }}
        />
      ),
    };
  };

  const columns: GridColDef[] = [
    projectReferenceColDef(false),
    projectTitleColDef(false),
    PhaseKindColDef,
    {
      ...currencyColDef('remained', 'Honoraires restants', false),
      editable: false,
      width: 120,
      aggregable: false,
      availableAggregationFunctions: [],
      renderHeader: () => (
        <Box
          sx={{ lineHeight: 'initial', textAlign: 'center', fontWeight: '500' }}
        >
          Honoraires
          <br />
          Restants
        </Box>
      ),
    },
    dayColDef(1),
    dayColDef(2),
    dayColDef(3),
    dayColDef(4),
    dayColDef(5),
  ];

  return (
    <>
      <AppSnackbar
        isOpen={isUpdateSuccess}
        severity="success"
        message="Modification enregistrée"
      />
      {error !== undefined && (
        <AppSnackbar
          isOpen={true}
          severity="error"
          message={(error as Error).message}
          autoHideDuration={5000}
        />
      )}
      <Box height="calc(100vh - 350px)">
        <AppDataGrid
          apiRef={apiRef}
          initialState={initialState}
          rows={rows}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={() => {}}
          hideFooter
          sx={{
            '& .MuiDataGrid-aggregationColumnHeader': {
              height: '56px',
            },
          }}
          slots={{
            toolbar: Toolbar,
          }}
          slotProps={{
            toolbar: {
              current,
              excludedPhaseIds:
                currentUserPhasesData?.map((phase) => phase.id) ?? [],
            },
          }}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
          }}
        />
      </Box>
    </>
  );
};

export default HourRecordingList;
