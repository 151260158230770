import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endOfMonth, parseISO, startOfMonth } from 'date-fns';

export const DataGridStateKey = 'DataGridState';

export interface DataGridState {
  expandedRowKeys: string[];
  quickFilterValues: string[];
}

export interface PlanningDataGridState extends DataGridState {
  startDate: Date;
  endDate: Date;
}

export type DataGridName =
  | 'project'
  | 'planningHour'
  | 'planningProject'
  | 'hourRecording'
  | 'user';

const defaultDataGridState = {
  expandedRowKeys: [],
  quickFilterValues: [],
};

const defaultPlanningDataGridState = {
  expandedRowKeys: [],
  quickFilterValues: [],
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date()),
};

const defaultState: Record<DataGridName, DataGridState> = {
  project: defaultDataGridState,
  planningHour: defaultDataGridState,
  planningProject: defaultPlanningDataGridState,
  hourRecording: defaultDataGridState,
  user: defaultDataGridState,
};

const initialState = () => {
  if (localStorage.getItem(DataGridStateKey)) {
    const state = JSON.parse(localStorage.getItem(DataGridStateKey)!);
    return {
      ...state,
      planningProject: {
        ...state.planningProject,
        startDate: parseISO(state.planningProject.startDate),
        endDate: parseISO(state.planningProject.endDate),
      },
    };
  } else {
    return defaultState;
  }
};

const dataGridSlice = createSlice({
  name: 'dataGrid',
  initialState: initialState() as Record<DataGridName, DataGridState>,
  reducers: {
    changeQuickFilterValues: (
      state,
      action: PayloadAction<{ name: DataGridName; values: string[] }>
    ) => {
      const { name, values } = action.payload;
      state[name].quickFilterValues = values;
      localStorage.setItem(DataGridStateKey, JSON.stringify(state));
    },
    changeExpandedRowKeys: (
      state,
      action: PayloadAction<{
        name: DataGridName;
        rowKey: string;
        expanded: boolean;
      }>
    ) => {
      const { name, rowKey, expanded } = action.payload;
      if (expanded) {
        state[name].expandedRowKeys.push(rowKey);
      } else {
        state[name].expandedRowKeys = state[name].expandedRowKeys.filter(
          (id) => id !== rowKey
        );
      }
      localStorage.setItem(DataGridStateKey, JSON.stringify(state));
    },
    changePeriod: (
      state,
      action: PayloadAction<{
        name: DataGridName;
        startDate: Date;
        endDate: Date;
      }>
    ) => {
      const { name, startDate, endDate } = action.payload;
      (state[name] as PlanningDataGridState).startDate = startDate;
      (state[name] as PlanningDataGridState).endDate = endDate;
      localStorage.setItem(DataGridStateKey, JSON.stringify(state));
    },
    reset: () => {
      localStorage.removeItem(DataGridStateKey);
      return defaultState;
    },
  },
});

export default dataGridSlice;
