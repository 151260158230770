import React from 'react';
import { AppBar, Box, Button, Container, Link, Toolbar } from '@mui/material';
import logo from '../../assets/logo.jpg';
import AccountMenu from 'src/components/Account/AccountMenu/AccountMenu';
import { useAuthentication } from 'src/hooks/useAuthentication';
import HeaderMenu from 'src/components/Header/HeaderMenu';
import { useLocation } from 'react-router-dom';

function Header() {
  const { isAuthenticated, availableRoutes } = useAuthentication();
  const location = useLocation();

  return (
    <AppBar position="fixed" color="transparent">
      <Toolbar sx={{ padding: 0 }}>
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <HeaderMenu />
          <Link
            href="/"
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Box
              component="img"
              alt="logo"
              src={logo}
              maxHeight={{ xs: '30px', sm: '80px' }}
              data-testid="branding-logo"
            />
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'right',
              marginRight: '2rem',
            }}
          >
            {isAuthenticated &&
              availableRoutes.map((route) => (
                <Button
                  href={route.path}
                  sx={{ marginX: '1rem' }}
                  key={route.key}
                  color={
                    location.pathname === route.path ? 'primary' : 'secondary'
                  }
                >
                  {route.label}
                </Button>
              ))}
          </Box>
          {isAuthenticated && <AccountMenu />}
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
