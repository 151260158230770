import { Hour } from '../models/Hour';
import { parseISO } from 'date-fns';
import { getURLQuery } from 'src/utils/fetchUtils';
import { DateHours } from 'src/models/PhaseHoursByDate';
import { api } from 'src/services/api.service';

const parseHour = (p: any): Hour => ({
  ...p,
  date: p.date ? parseISO(p.date) : undefined,
});

export const hourApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findHours: builder.query<Hour[], FindHoursOptions>({
      query: (opts) => `hours${getURLQuery(opts)}`,
      transformResponse: (results: Hour[]) => [...results.map(parseHour)],
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ userId, phaseId }) => ({
                type: 'Hour' as const,
                userId,
                phaseId,
              })),
              { type: 'Hour', id: 'LIST' },
            ]
          : [{ type: 'Hour', id: 'LIST' }],
    }),
    saveHourByDates: builder.mutation<
      void,
      { phaseId: string; userId: string; hoursByDate: DateHours[] }
    >({
      query: ({ phaseId, userId, hoursByDate }) => ({
        url: `hours/phase/${phaseId}/user/${userId}`,
        method: 'POST',
        body: { hoursByDate },
      }),
      transformErrorResponse(error) {
        return error.data;
      },
      invalidatesTags: (result, error, hour) => [
        {
          type: 'Hour',
          userId: hour.userId,
          phaseId: hour.phaseId,
        },
      ],
    }),
  }),
});

export const {
  useFindHoursQuery,
  useSaveHourByDatesMutation,
  useLazyFindHoursQuery,
} = hourApi;
