import React from 'react';
import { Box, Button, DialogActions } from '@mui/material';
import {
  GRID_AGGREGATION_FUNCTIONS,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridAggregationFunction,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import {
  useFindPhasesQuery,
  useNotifyCompletionMutation,
  useUpdatePhaseMutation,
} from 'src/services/phase.service';
import { getDepartmentRate, Phase } from 'src/models/Phase';
import AppSnackbar from 'src/components/AppSnackbar/AppSnackbar';
import ProjectEditCell from 'src/components/ProjectList/ProjectEditCell';
import { Department } from 'shared/types/Department';
import AddProjectButton from 'src/components/ProjectList/AddProjectButton';
import PhasesUsersEditCell from 'src/components/ProjectList/PhaseUsersEditCell';
import {
  currencyColDef,
  currencyFormatter,
  PhaseKindColDef,
  projectReferenceColDef,
  projectTitleColDef,
  rateColDef,
} from 'src/utils/columnUtils';
import ProjectPhasesDialog from 'src/components/ProjectPhasesDialog/ProjectPhasesDialog';
import ExportToolbar from 'src/components/ExportToolbar/ExportToolbar';
import { useDataGrid } from 'src/hooks/useDataGrid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import AppDataGrid from 'src/components/AppDataGrid/AppDataGrid';

const ProjectList = () => {
  const { apiRef, isGroupExpandedByDefault, initialStateFilter } =
    useDataGrid('project');

  const { data } = useFindPhasesQuery({});
  const phases = data ?? [];

  const [updatePhase, { isSuccess: isUpdateSuccess, error }] =
    useUpdatePhaseMutation();

  const [notifyCompletion, { isSuccess: isNotifySuccess }] =
    useNotifyCompletionMutation();

  const [achievedPhase, setAchievedPhase] = React.useState<string>();

  const getProject = (id: string) => {
    return phases.find((phase) => phase.project.id === id)?.project;
  };

  const processRowUpdate = async (
    updatedRow: GridValidRowModel,
    originalRow: GridValidRowModel
  ) => {
    const { projectId, projectReference, projectTitle, ...phase } = updatedRow;
    if (
      (phase as Phase).progressRate === 0 &&
      (originalRow as Phase).progressRate !== 0
    ) {
      setAchievedPhase((phase as Phase).id);
    }
    await updatePhase(phase as Phase);
    return updatedRow;
  };

  const rows: GridRowsProp = phases.map((phase) => ({
    ...phase,
    projectId: phase.project.id,
    projectReference: phase.project.reference,
    projectTitle: phase.project.title,
  }));

  const usersColumn = (
    field: string,
    department: Department,
    getUsers: (phase: Phase) => string[] | undefined
  ): GridColDef => ({
    field,
    headerName: '',
    width: 60,
    align: 'center',
    disableColumnMenu: true,
    type: 'actions',
    sortable: false,
    getActions: ({ row }: GridRowParams<Phase>) =>
      getUsers(row) !== undefined
        ? [
            <PhasesUsersEditCell
              phaseId={row.id}
              userIds={getUsers(row)!}
              department={department}
            />,
          ]
        : [],
  });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...initialStateFilter,
      rowGrouping: {
        model: ['projectId'],
      },
      aggregation: {
        model: {
          initialAmount: 'sum',
          additionalAmount: 'sum',
          totalAmount: 'sum',
          remainedAmount: 'sum',
          progressRate: 'remained',
          thermalRate: 'remainedThermal',
          electricityRate: 'remainedElectricity',
          structuralRate: 'remainedStructural',
          economyRate: 'remainedEconomy',
        },
      },
      pinnedColumns: {
        left: [
          'projectId',
          'actions',
          'projectReference',
          'projectTitle',
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
          'kind',
        ],
      },
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      width: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const projectId = id.toString().split('/')[1];
        return projectId ? [<ProjectEditCell projectId={projectId} />] : [];
      },
    },
    {
      ...projectReferenceColDef(true),
      renderCell: (params) =>
        params.rowNode.type === 'group'
          ? getProject(params.rowNode.groupingKey as string)?.reference
          : '',
    },
    {
      ...projectTitleColDef(true),
      renderCell: (params) =>
        params.rowNode.type === 'group'
          ? getProject(params.rowNode.groupingKey as string)?.title
          : '',
    },
    {
      field: 'projectId',
      headerName: '',
      type: 'string',
      filterable: false,
      width: 0,
      sortable: false,
      resizable: false,
      aggregable: false,
      disableColumnMenu: true,
      renderCell: () => '',
    },
    {
      ...PhaseKindColDef,
      width: 65,
      groupable: false,
      aggregable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        params.rowNode.type === 'group' ? (
          <ProjectPhasesDialog
            projectId={getProject(params.rowNode.groupingKey as string)!.id}
          />
        ) : (
          params.value
        ),
    },
    currencyColDef('initialAmount', 'Base contrat'),
    currencyColDef('additionalAmount', 'Avenants'),
    {
      ...currencyColDef('totalAmount', 'Base + avenants'),
      editable: false,
      valueGetter: (params) =>
        params.row.initialAmount + params.row.additionalAmount,
    },
    rateColDef('progressRate', 'Avancement'),
    {
      field: 'startDate',
      headerName: 'Démarrage',
      width: 130,
      type: 'date',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'endDate',
      headerName: 'Fin de mission',
      width: 130,
      type: 'date',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    rateColDef('thermalRate', 'Thermique'),
    usersColumn('thermalUsers', 'THERMAL', (phase) => phase.thermalUsers),
    rateColDef('electricityRate', 'Electricité'),
    usersColumn(
      'electricityUsers',
      'ELECTRICITY',
      (phase) => phase.electricityUsers
    ),
    rateColDef('structuralRate', 'Structure'),
    usersColumn(
      'structuralUsers',
      'STRUCTURAL',
      (phase) => phase.structuralUsers
    ),
    rateColDef('economyRate', 'Economie'),
    usersColumn('economyUsers', 'ECONOMY', (phase) => phase.economyUsers),
  ];

  const remained = (
    department?: Department
  ): GridAggregationFunction<Phase, string> => ({
    label: 'à produire',
    getCellValue: ({ row }) => row as Phase,
    apply: ({ values }) => {
      let amount = 0;
      values.forEach((phase) => {
        if (phase) {
          const progressRate = phase?.progressRate ?? null;
          const rate = department
            ? getDepartmentRate(phase, department) ?? null
            : 100;
          if (
            phase.initialAmount !== null &&
            phase.additionalAmount !== null &&
            progressRate !== null &&
            rate !== null
          ) {
            amount +=
              ((phase.initialAmount + phase.additionalAmount) *
                progressRate *
                rate) /
              10000;
          }
        }
      });
      return currencyFormatter({ value: amount });
    },
    columnTypes: ['number'],
  });

  const toolbar = () => (
    <>
      <ExportToolbar
        additionalButtons={[<AddProjectButton key={'add_project_button'} />]}
        showQuickFilter={true}
      />
    </>
  );

  return (
    <>
      <AppSnackbar
        isOpen={isUpdateSuccess}
        severity="success"
        message="Modification enregistrée"
      />
      <AppSnackbar
        isOpen={isNotifySuccess}
        severity="success"
        message="Email envoyé"
      />
      {error !== undefined && (
        <AppSnackbar
          isOpen={true}
          severity="error"
          message={(error as Error).message}
          autoHideDuration={5000}
        />
      )}
      {achievedPhase && (
        <Dialog open={true} onClose={() => setAchievedPhase(undefined)}>
          <DialogTitle>Phase terminée</DialogTitle>
          <DialogContent>
            La phase est terminée, souhaitez-vous envoyer un mail à la
            comptabilité pour facturation ?
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setAchievedPhase(undefined)}
            >
              Non
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                await notifyCompletion(achievedPhase);
                setAchievedPhase(undefined);
              }}
            >
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box height="calc(100vh - 250px)">
        <AppDataGrid
          apiRef={apiRef}
          initialState={initialState}
          rows={rows}
          columns={columns}
          processRowUpdate={processRowUpdate}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
          slots={{
            toolbar,
          }}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            remained: remained(),
            remainedThermal: remained('THERMAL'),
            remainedElectricity: remained('ELECTRICITY'),
            remainedStructural: remained('STRUCTURAL'),
            remainedEconomy: remained('ECONOMY'),
          }}
        />
      </Box>
    </>
  );
};

export default ProjectList;
