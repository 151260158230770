import React from 'react';
import { useGetPhaseQuery } from 'src/services/phase.service';

interface Props {
  phaseId: string;
}

const PhaseKind = ({ phaseId }: Props) => {
  const { data: phase } = useGetPhaseQuery(phaseId);

  return <>{phase?.kind}</>;
};

export default PhaseKind;
