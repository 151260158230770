import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const AppSmallBadge = styled(Badge)<BadgeProps>(({}) => ({
  '& .MuiBadge-badge': {
    maxHeight: '16px',
    width: '16px',
    minWidth: '16px',
    fontSize: '10px',
  },
}));

export default AppSmallBadge;
