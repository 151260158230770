import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'src/hooks/useForm';
import { Add } from '@mui/icons-material';
import { useCreatePhaseMutation } from 'src/services/phase.service';
import { PhaseKind, PhaseKinds } from 'shared/types/PhaseKind';
import AppSnackbar from 'src/components/AppSnackbar/AppSnackbar';

export interface Props {
  projectId: string;
}

function AddPhase({ projectId }: Props) {
  const [createPhase, { isSuccess: isCreateSuccess }] =
    useCreatePhaseMutation();

  const [phaseKind, setPhaseKind] = useState<PhaseKind>();

  const schema = yup.object().shape({
    phaseKind: yup.string().required('Veuillez sélectionner une phase.'),
  });

  const { message, validate } = useForm(schema, {
    phaseKind,
  });

  const submitPhase = async () => {
    await validate(() =>
      createPhase({
        projectId: projectId,
        kind: phaseKind!,
      })
    );
  };

  return (
    <>
      <AppSnackbar
        isOpen={isCreateSuccess}
        severity="success"
        message="Modification enregistrée"
      />
      <FormControl variant="standard" sx={{ minWidth: '50%' }}>
        <InputLabel id="add-phase-label">Ajouter une phase</InputLabel>
        <Select
          labelId="add-phase-label"
          value={phaseKind ?? ''}
          onChange={(event) => setPhaseKind(event.target.value as PhaseKind)}
          MenuProps={{
            PaperProps: { sx: { maxHeight: 100 } },
          }}
          autoWidth
        >
          {PhaseKinds.map((phaseKind) => (
            <MenuItem value={phaseKind} key={phaseKind}>
              {phaseKind}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{message('phaseKind')}</FormHelperText>
      </FormControl>
      <Button
        sx={{ verticalAlign: 'bottom', p: '0.25rem', marginLeft: '1rem' }}
        color="primary"
        onClick={submitPhase}
        startIcon={<Add />}
        variant="outlined"
      >
        Ajouter
      </Button>
    </>
  );
}

export default AddPhase;
