import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { Brand } from 'shared/constants';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        paddingY: '0.1rem',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid lightgrey',
        width: '100%',
        minHeight: '35px',
        display: 'flex',
        zIndex: 9999,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
        }}
      >
        <Typography
          color="textSecondary"
          variant="subtitle2"
          lineHeight="unset"
          width="100%"
          textAlign="center"
        >
          {`©${new Date().getFullYear()}`} {Brand} - Tous droits réservés.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
