import { AuthUser, UserInfos } from 'src/models/User';
import { api } from 'src/services/api.service';

export const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfos: builder.query<UserInfos, string>({
      query: (userId) => ({
        url: `accounts/${userId}/infos`,
      }),
      providesTags: () => ['User'],
    }),
    signIn: builder.mutation<AuthUser, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: 'accounts/sign-in',
        method: 'POST',
        body: { email, password },
      }),
      transformResponse: (result: any) => ({
        userId: result.userId,
        accessToken: result.accessToken,
      }),
      invalidatesTags: ['User'],
    }),
    updateUserInfos: builder.mutation<
      void,
      { userId: string; userInfos: UserInfos }
    >({
      query: ({ userId, userInfos }) => ({
        url: `accounts/${userId}/infos`,
        method: 'PUT',
        body: userInfos,
      }),
      invalidatesTags: ['User'],
    }),
    updatePassword: builder.mutation<
      void,
      { userId: string; password: string; newPassword: string }
    >({
      query: ({ userId, password, newPassword }) => ({
        url: `accounts/${userId}/password`,
        method: 'PUT',
        body: { password, newPassword },
      }),
    }),
    sendPasswordLink: builder.mutation<string, string>({
      query: (email) => ({
        url: 'accounts/password-link',
        method: 'POST',
        body: { email },
      }),
      transformResponse: (baseQueryReturnValue, meta, email) => email,
    }),
    resetPassword: builder.mutation<
      void,
      { tokenId: string; newPassword: string }
    >({
      query: ({ tokenId, newPassword }) => ({
        url: `accounts/reset-password?tokenId=${tokenId}`,
        method: 'PUT',
        body: { newPassword },
      }),
    }),
  }),
});

export const {
  useGetUserInfosQuery,
  useSignInMutation,
  useUpdateUserInfosMutation,
  useUpdatePasswordMutation,
  useSendPasswordLinkMutation,
  useResetPasswordMutation,
} = accountApi;
