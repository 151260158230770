import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Delete, Settings } from '@mui/icons-material';
import {
  useDeletePhaseMutation,
  useFindPhasesQuery,
} from 'src/services/phase.service';
import AddPhase from 'src/components/ProjectPhasesDialog/AddPhase';
import AppSnackbar from 'src/components/AppSnackbar/AppSnackbar';

export interface Props {
  projectId: string;
}

function ProjectPhasesDialog({ projectId }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: projectPhases } = useFindPhasesQuery(
    { projectId },
    { skip: !dialogOpen }
  );
  const [deletePhase, { isSuccess: isDeleteSuccess }] =
    useDeletePhaseMutation();

  return (
    <>
      <IconButton onClick={() => setDialogOpen(true)}>
        <Settings fontSize="small" />
      </IconButton>
      <Dialog open={dialogOpen} scroll="paper" disableScrollLock fullWidth>
        <DialogTitle>Gestion des phases</DialogTitle>

        <DialogContent>
          <AppSnackbar
            isOpen={isDeleteSuccess}
            severity="success"
            message="Modification enregistrée"
          />
          <FormControl component="fieldset" variant="outlined">
            <FormLabel component="label" sx={{ marginTop: '1rem' }}>
              Phases
            </FormLabel>
            <FormGroup
              sx={{
                display: 'grid',
                columnGap: 3,
                rowGap: 1,
                gridTemplateColumns: 'repeat(4, 1fr)',
              }}
            >
              <List
                sx={{
                  display: 'grid',
                  columnGap: 10,
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}
              >
                {(projectPhases ?? []).map((phase) => (
                  <ListItem
                    key={'phase_' + phase.id}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={async () => await deletePhase(phase.id)}
                        size="small"
                        color="error"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemText>{phase.kind}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </FormGroup>
          </FormControl>
          <AddPhase projectId={projectId} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setDialogOpen(false)}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProjectPhasesDialog;
