import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'src/hooks/useForm';
import { UserRole } from 'shared/types/UserRole';
import { useUpdateUserInfosMutation } from 'src/services/account.service';
import { Brand } from 'shared/constants';
import { UserInfos } from 'src/models/User';
import AppError from 'src/components/AppError/AppError';

interface Props {
  userId: string;
  userInfos: UserInfos;
  onSubmit?: () => void;
}

const AccountInfosForm = ({ userId, userInfos, onSubmit }: Props) => {
  const [firstName, setFirstName] = useState(userInfos.firstName);
  const [lastName, setLastName] = useState(userInfos.firstName);
  const [role, setRole] = useState<UserRole>(userInfos.role);

  const [updateUserInfos, { isError: isUpdateError }] =
    useUpdateUserInfosMutation();

  const schema = yup.object().shape({
    firstName: yup.string().min(3, 'Veuillez renseigner votre prénom.'),
    lastName: yup.string().min(3, 'Veuillez renseigner votre nom.'),
    role: yup.string().min(1, 'Veuillez sélectionner votre profil'),
  });

  const { message, validate, messageType } = useForm(schema, {
    firstName: firstName,
    lastName: lastName,
    roles: role,
  });

  const submit = async () => {
    await validate(() => {
      updateUserInfos({
        userId,
        userInfos: {
          email: userInfos.email,
          firstName,
          lastName,
          role,
        },
      })
        .unwrap()
        .then(() => {
          onSubmit?.();
        });
    });
  };

  const handleRoleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    role: UserRole
  ) => {
    //TODO
    // setRole([
    //   ...role.filter((_) => _ !== role),
    //   ...(e.target.checked ? [role] : []),
    // ]);
  };

  return (
    <Box component="form">
      <TextField
        value={userInfos?.email}
        disabled
        autoFocus
        label="Votre email"
        type="email"
        fullWidth
        margin="normal"
        variant="standard"
        required
      />
      <TextField
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        autoFocus
        label={`Votre nom d'utilisateur sur ${Brand}`}
        type="text"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('firstName') === 'error'}
        required
      />
      <FormHelperText error>{message('firstName')}</FormHelperText>
      <FormControl
        fullWidth
        variant="standard"
        margin="normal"
        error={messageType('roles') === 'error'}
      >
        <FormLabel>
          Votre profil (vous pouvez sélectionner plusieurs réponses)
        </FormLabel>
        <FormGroup>
          {/*<AccountRoleCheckbox*/}
          {/*  checkboxRole={'PondOwner'}*/}
          {/*  userRoles={role}*/}
          {/*  onChange={handleRoleCheckboxChange}*/}
          {/*/>*/}
          {/*<AccountRoleCheckbox*/}
          {/*  checkboxRole={'Guide'}*/}
          {/*  userRoles={role}*/}
          {/*  onChange={handleRoleCheckboxChange}*/}
          {/*/>*/}
          {/*<AccountRoleCheckbox*/}
          {/*  checkboxRole={'Customer'}*/}
          {/*  userRoles={roles}*/}
          {/*  onChange={handleRoleCheckboxChange}*/}
          {/*/>*/}
        </FormGroup>
      </FormControl>
      <FormHelperText error>{message('roles')}</FormHelperText>
      <AppError open={isUpdateError} />
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginY: '2rem' }}
        fullWidth
      >
        Enregistrer
      </Button>
    </Box>
  );
};

export default AccountInfosForm;
