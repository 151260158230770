import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@mui/material';
import { Project } from 'src/models/Project';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { useForm } from 'src/hooks/useForm';
import { Delete } from '@mui/icons-material';
import { useDeleteProjectMutation } from 'src/services/project.service';

export interface Props {
  project: Project;
  onSubmit: (project: Project) => void;
  open: boolean;
  onClose: () => void;
}

function ProjectDialog({ project, onSubmit, onClose, open }: Props) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteProject] = useDeleteProjectMutation();

  const [reference, setReference] = useState(project?.reference);
  const [title, setTitle] = useState(project?.title);

  const schema = yup.object().shape({
    reference: yup
      .string()
      .required()
      .required('Veuillez renseigner le référence.'),
    title: yup.string().required().required('Veuillez renseigner le titre.'),
  });

  const { message, validate, messageType } = useForm(schema, {
    reference,
    title,
  });

  const submitProject = async () => {
    await validate(() =>
      onSubmit({ ...project, reference: reference!, title: title! })
    );
  };

  const submitDeleteProject = async () => {
    if (project) {
      await deleteProject(project?.id);
    }
    setDeleteDialogOpen(false);
    onClose();
  };

  return (
    <Box component="form" onSubmit={submitProject}>
      <Dialog open={open} scroll="paper" disableScrollLock fullWidth>
        <DialogTitle>
          Affaire {project.reference} - {project.title}
        </DialogTitle>

        <DialogContent>
          <TextField
            value={reference}
            onChange={(event) => setReference(event.target.value)}
            autoFocus
            label="Référence"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('reference') === 'error'}
          />
          <FormHelperText error>{message('reference')}</FormHelperText>
          <TextField
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            autoFocus
            label="Nom de l'affaire"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('title') === 'error'}
          />
          <FormHelperText error>{message('title')}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={submitProject}>
            Enregistrer les modifications
          </Button>
        </DialogActions>
        {project && (
          <DialogActions>
            <Button
              color="error"
              startIcon={<Delete />}
              size="small"
              onClick={() => setDeleteDialogOpen(true)}
            >
              Supprimer l'affaire
            </Button>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              color="warning"
            >
              <DialogTitle>Supprimer l'affaire</DialogTitle>
              <DialogContent>
                <Alert severity="warning">
                  Êtes-vous sûr de vouloir supprimer l'affaire  
                  {project.reference} - {project.title} ?
                </Alert>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setDeleteDialogOpen(false)}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={submitDeleteProject}
                >
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}

export default ProjectDialog;
