import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useCreateUserMutation } from 'src/services/user.service';
import AddUserDialog from 'src/components/UserList/AddUserDialog';
import { DraftUser } from 'src/models/User';

const AddUserButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [createUser] = useCreateUserMutation();

  const submitUser = async (user: DraftUser) => {
    await createUser(user);
    setDialogOpen(false);
  };

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setDialogOpen(true)}>
        Ajouter un collaborateur
      </Button>
      <AddUserDialog
        onSubmit={submitUser}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default AddUserButton;
