import React, { useEffect } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

interface Props {
  isOpen: boolean;
  severity: AlertColor;
  message: string;
  autoHideDuration?: number;
}

const AppSnackbar = ({
  severity,
  isOpen,
  message,
  autoHideDuration,
}: Props) => {
  const [open, setOpen] = React.useState<boolean>();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration ?? 2000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
