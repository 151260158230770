import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Alert, Box, Container, FormHelperText } from '@mui/material';
import * as yup from 'yup';
import { emailValidator, passwordValidator, useForm } from 'src/hooks/useForm';
import { useSignInMutation } from 'src/services/account.service';
import { useAppDispatch } from 'src/hooks/useStore';
import authSlice from 'src/store/reducers/authSlice';

interface Props {
  onNavigate?: () => void;
  onConnect?: () => void;
}

const AccountSigninForm = ({ onConnect }: Props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signIn, { isError: isSignInError }] = useSignInMutation();

  const schema = yup.object().shape({
    email: emailValidator,
    password: passwordValidator,
  });

  const { message, validate, messageType } = useForm(schema, {
    email,
    password,
  });

  const submit = async () => {
    await validate(() => {
      signIn({
        email,
        password,
      })
        .unwrap()
        .then((authUser) => {
          dispatch(authSlice.actions.signinUser({ authUser }));
          onConnect?.();
        });
    });
  };

  return (
    <Container maxWidth="sm">
      <Box component="form">
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
          label="Votre email"
          type="email"
          fullWidth
          margin="normal"
          variant="standard"
          error={messageType('email') === 'error'}
          required
        />
        <FormHelperText error>{message('email')}</FormHelperText>
        <TextField
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          autoFocus
          label="Mot de passe"
          type="password"
          fullWidth
          margin="normal"
          variant="standard"
          error={messageType('password') === 'error'}
          required
        />
        <FormHelperText error>{message('password')}</FormHelperText>
        {isSignInError && (
          <Alert severity="error">
            Identifiants non valide, veuillez réessayer.
          </Alert>
        )}
        <Button
          variant="contained"
          onClick={submit}
          sx={{ marginTop: '2rem' }}
          fullWidth
        >
          Me connecter
        </Button>
      </Box>
    </Container>
  );
};

export default AccountSigninForm;
