import { Department } from 'shared/types/Department';

export interface Hour {
  phaseId: string;
  userId: string;
  date: Date;
  hours: number;
  cost: number;
  department: Department;
}

export const reduceHoursByUser = (hours: Hour[]): Omit<Hour, 'date'>[] =>
  hours
    .filter((hour) => hour.hours > 0)
    .reduce((acc, hour) => {
      const userHour = acc.find(
        (u) => u.userId === hour.userId && u.phaseId === hour.phaseId
      );
      if (userHour) {
        userHour.hours += hour.hours;
        userHour.cost += hour.cost;
      } else {
        acc.push({
          userId: hour.userId,
          hours: hour.hours,
          phaseId: hour.phaseId,
          cost: hour.cost,
          department: hour.department,
        });
      }
      return acc;
    }, [] as Omit<Hour, 'date'>[]);
