import React, { useMemo } from 'react';
import { Alert, AlertTitle, Box, Container, Typography } from '@mui/material';
import { H1 } from 'src/components/Styled/Styled';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { useResetPasswordMutation } from 'src/services/account.service';
import { Brand } from 'shared/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AccountSigninForm from 'src/components/Account/AccountSigninForm/AccountSigninForm';
import AccountResetPasswordForm from 'src/components/Account/AccountResetPasswordForm/AccountResetPasswordForm';

const SetPasswordView = () => {
  useDocumentTitle('Définissez votre mot de passe');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tokenId = useMemo(() => searchParams.get('tokenId'), [searchParams]);

  const [
    _, // eslint-disable-line @typescript-eslint/no-unused-vars
    { isSuccess: isResetPasswordSuccess },
  ] = useResetPasswordMutation({
    fixedCacheKey: 'shared-reset-password',
  });

  return (
    <Container maxWidth="sm">
      {isResetPasswordSuccess ? (
        <Box>
          <H1>La saisie de votre mot de passe est terminée.</H1>
          <Alert severity="success" sx={{ marginY: '2rem' }}>
            <AlertTitle>Connectez-vous</AlertTitle>
            <Typography sx={{ paddingBottom: '1rem' }}>
              Vous pouvez maintenant profiter de {Brand} en vous connectant avec
              votre email et votre mot de passe.
            </Typography>
          </Alert>
          <AccountSigninForm onConnect={() => navigate('/')} />
        </Box>
      ) : (
        <>
          <H1>Définissez votre mot de passe</H1>
          <AccountResetPasswordForm tokenId={tokenId!} />
        </>
      )}
    </Container>
  );
};

export default SetPasswordView;
